<template>
  <div class="bg-bg_alt_color p-2 rounded shadow">
    <div v-if="getPlaces?.length" class="min-h-[50px]">
      <RikazGrid
        name="material"
        :items="getPlaces"
        margin="sm:ltr:-ml-3.5 sm:rtl:-mr-3.5"
      >
        <template #card="{ forKey, data }">
          <MyAccountPlacesCard
            :key="`place-grid-item-${forKey}`"
            :address="( data as AgentPlace )"
            @delete="deletePlaceLocal"
          />
        </template>
      </RikazGrid>
    </div>
    <MyAccountPlacesCreateForm />
  </div>
</template>

<script setup lang="ts">
import type { AgentPlace } from '~~/composables/useMenuModel'
const { deletePlace } = useDeletePlace()
const { getPlaces, fetchAgentPlace, AgentPlacesValue } = useAgentPlaces()
await fetchAgentPlace()
async function deletePlaceLocal(addressId: number) {
  if (await deletePlace(addressId)) {
    AgentPlacesValue.value.places.splice(
      AgentPlacesValue.value.places.findIndex((el) => el.id === addressId),
      1
    )
  }
}

</script>
