<template>
  <div
    class="bg-nav_color flex flex-col rounded-lg bg-opacity-5 w-48 my-3 xs:p-2 xs:ltr:mr-4 xs:rtl:ml-4 lg:ltr:mr-7 lg:rtl:ml-7"
  >
    <span
      v-if="address.text_address || address.city?.name"
      class="mb-auto font-light flex"
    >
      <div v-if="address.city?.name">{{ address.city?.name }} - </div>
      <div v-if="address.text_address"> {{ address.text_address }}</div>
    </span>
    <div class="font-light text-xs">
      <span v-if="address.street">{{ address.street }}, </span>
      <span v-if="address.building" class="mx-1">{{ address.building }}, </span>
    </div>
    <div class="font-light text-xs">
      <span v-if="address.apartment">{{ address.apartment }}</span>
      <span v-if="address.floor" class="mx-1">{{ address.floor }}</span>
    </div>
    <span class="font-light text-xs">{{ address.details }}</span>

    <div class="flex-auto"></div>

    <SelaDialouge width="auto">
      <template #activator="{ props: propsTemp }">
        <div class="flex items-end m-2"></div>
        <div
          class="text-nav_color cursor-pointer text-xs ltr:ml-auto rtl:mr-auto whitespace-nowrap"
          v-bind="propsTemp"
        >
          {{ t('delete_address') }}
        </div>
      </template>
      <template #content="{ close }">
        <div class="sela-container w-full bg-bg_alt_color my-4">
          <v-locale-provider :rtl="useIsRtl()" class="w-full">
            <div class="text-nav_color">
              <div class="text-xl font-bold mb-4">
                {{
                  t('want_to_delete_the_place', {
                    address: address.text_address
                  })
                }}
              </div>
              <div class="flex">
                <SelaSubmitButton
                  :title="t('yes')"
                  type="button"
                  :loading="false"
                  @click="closeDialouge(close)"
                />
                <div class="w-12"></div>
                <SelaSubmitButton
                  :title="t('no')"
                  type="button"
                  :loading="false"
                  @click="close"
                />
              </div>
            </div>
          </v-locale-provider>
        </div>
      </template>
    </SelaDialouge>

    <SelaResponseError
      class="my-2"
      :api-key="`deleteAgentPlace-${address.id}`"
    />
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    address: AgentPlace
  }>(),
  {}
)

const { t } = useI18n()

function closeDialouge(close: any) {
  close()
  emit('delete', props.address.id)
}

const emit = defineEmits(['delete'])
</script>
